import { Component, inject, Input } from '@angular/core';
import { IOrganisation } from 'src/app/views/user-management/model/user-management.model';
import { Observable, shareReplay } from "rxjs";
import { ThemeService } from "../../services/theme.service";
import { selectIsReportingV2 } from "../../../store";
import { Store } from "@ngrx/store";

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html'
})
export class MainLayoutComponent {
  @Input() userOrganisation: IOrganisation;
  private themeService = inject(ThemeService);
  private store = inject(Store);

  _stickBreadcrumbs: boolean = true;
  isDarkMode$: Observable<boolean> = this.themeService.isDarkMode();
  isReportingV2$ = this.store.select(selectIsReportingV2).pipe(shareReplay(1));

  @Input() set sticky(value) {
    // it's undefined by default
    if (value !== undefined) {
      this._stickBreadcrumbs = value
    } else {
      // default it to true for same behaviour as before
      this._stickBreadcrumbs = true;
    }
  }

  get sticky() {
    return this._stickBreadcrumbs;
  }
}
